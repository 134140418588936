import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import BecomeMemberSmall from "../components/become-member-small/become-member-small";
import ManagementMember from "../components/management-member/management-member";

import './ueber-uns.sass'
import CommitteeMembers from "../components/committee-members/committee-members";
import {graphql} from "gatsby";

const ÜberUnsPage = ({data}) => {

    const yml = data.aboutYaml;

    const texts = yml.text;
    const totalTextLength = texts.map(t => t.length).reduce((total, val) => total + val);
    const columnLength = totalTextLength / 3;

    const groupedTexts = [[],[],[]];

    let textsIndex = 0;
    for (let i = 0; i < 3; i++) {
        let length = 0;

        do {
            groupedTexts[i].push(texts[textsIndex]);
            length += texts[textsIndex].length;
            textsIndex++;
        } while (length <= columnLength && texts[textsIndex])
    }


    return (
        <Layout>
            <SEO title="Über Uns"/>
            <div className={'aboutPage container section bottom'}>

                <h1 className={'title'}>Über Uns</h1>
                <h4 className={'subtitle'}>{yml.title}</h4>
                <div className="container">

                    <div className="grid">
                        {groupedTexts.map((group, groupIndex) => (<div key={groupIndex} className="col-4 col-12td col-12md col-12smd padding text-container">
                            {group.map((text, i) =>
                                (<p key={i}>{text}</p>)
                            )}
                        </div>))}
                    </div>

                </div>
            </div>
            <BecomeMemberSmall></BecomeMemberSmall>
            <div className="fourth-container section bottom">
                <h4 className={'subtitle'}>Aktuelle Vorstandschaft
                </h4>
                <div className="grid">
                    { yml.management_members.map((member, i) => (<div key={i} className="col-3 col-6td col-12md col-12smd aboutPage--management-member-grid">
                        <div className="aboutPage--management-member">
                            <ManagementMember member={member}></ManagementMember>
                        </div>
                    </div>)) }
                </div>
                <CommitteeMembers members={yml.committee_members}></CommitteeMembers>
            </div>
        </Layout>
    );
}

export default ÜberUnsPage


export const pageQuery = graphql`
    query AboutQuery {
      aboutYaml {
        management_members {
          image
          position
          subtitle
          title
        }
        committee_members {
          title
          subtitle
        }
        text
        title
      }
    }
  `;
