import React from "react";
import PropTypes from "prop-types"

import "../management-member/management-member.sass"
import {toPath} from "../../util/toPath";

const ManagementMember = ({member}) => {

    return (
        <div
            className={'management-member'}
        >
            <div className={'management-member--content'}>
                <span className="avatar">
                    <img src={toPath("/_prev", member.image)} alt=""/>
                </span>
                <div className="management-member--info">

                    <p className="position">{member.position}</p>
                    <h5 className="name">{member.title}</h5>
                    <p className="company">{member.subtitle}</p>
                </div>
            </div>
        </div>
    )
};

ManagementMember.propTypes = {
    children: PropTypes.node,
};

export default ManagementMember
