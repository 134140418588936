import React from "react";
import PropTypes from "prop-types"

import "../committee-members/committee-members.sass"

const CommitteeMembers = ({members}) => {

    return (
        <div
            className={'fourth-container section bottom committee-members'}
        >
            <h4 className="subtitle">Ausschussmitglieder</h4>
            <div className="grid">

                {members.map((member, i) => (<div key={i} className="col-4 col-6td col-12md col-12smd left committee-members--member">
                    <h6>{member.title}</h6>
                    <p>{member.subtitle}</p>
                </div>))}
            </div>

        </div>
    )
};

CommitteeMembers.propTypes = {
    children: PropTypes.node,
};

export default CommitteeMembers
